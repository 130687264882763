import {IWeb3Facade} from "../IWeb3Facade";
import {
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {BASETokens} from "../../../../store/basescan/BASETokens";
import {createPublicClient, http} from "viem";
import {base, baseSepolia} from "viem/chains";
import {publicActionsL2} from "viem/op-stack";
import {GasHelper} from "../../../../helpers";
import {BASETestnetTokens} from "../../../../store/basescan/BASETestnetTokens";
import {HexStr} from "../../../../store/web3/web3";

export const BaseTransactionPriorityEnum: ITransactionPriorityEnum = {
  average: "average",
  fast: "fast"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IBaseGasPrice {
  average: bigint,
  fast: bigint
}

export const BaseInitData: InitDataType = {
  defaultTransactionPriority: BaseTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [BaseTransactionPriorityEnum.average]: "Average",
    [BaseTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API,
    devUrl: process.env.REACT_APP_LINK_FOR_BASE_SCAN_API
  },
  web3HttpProviderLink: process.env.REACT_APP_BASE_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? BASETestnetTokens : BASETokens,
  network: 'base',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BASE_SCAN,
  addressesChunkSize: 100
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IBaseGasPrice {
  return {
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class BASEFacade extends ETHFacade implements IWeb3Facade {

  constructor(initData?: InitDataType) {
    super(initData || BaseInitData)
  }

  getTimeout(): number {
    return 1000;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IBaseGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "id": 0,
          "method": "eth_gasPrice",
          "jsonrpc": "2.0",
          "params": []
        })
      })
      const result = await response.json() as {
        "jsonrpc": string,
        "result": HexStr,
        "id": number
      }
      const slowInWei: bigint = BigInt(result.result)
      switch (transactionPriority) {
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 150)
      }
    }

    return gasPriceResult[transactionPriority as keyof IBaseGasPrice] || BigInt(0)
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)
    /**
     * Solution for L2 chain
     */
    const publicClient: any = createPublicClient({
      chain: this._environment === 'dev' ? baseSepolia : base,
      transport: http(),
    } as any).extend(publicActionsL2())

    const txDataByAddressIterator = txDataByAddress.values()
    const {done, value} = txDataByAddressIterator.next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    let item: ITxEthData = value
    /**
     * Solution if fee greater than balance
     * estimateL1Fee throws an exception if TX Value is lower than 0
     */
    while (item.value < 0) {
      const {done, value} = txDataByAddressIterator.next()
      if (done) {
        return {txDataByAddress, feeDataByAddress}
      }

      item = value
    }

    /**
     * Solution to estimate if balance too small to send transaction
     */
    try {
      const feeL1 = await publicClient.estimateL1Fee({
        account: item.from,
        to: item.to,
        value: item.value
      })
      const feeL1More = GasHelper.gasPay(feeL1)
      console.log('base feeL1', feeL1More)

      for (const address of txDataByAddress.keys()) {
        feeDataByAddress.set(address, feeDataByAddress.get(address)! + feeL1More)
        const item = txDataByAddress.get(address)!
        if (item.value - feeL1More < BigInt(0)) {
          txDataByAddress.delete(address)
        } else {
          item.value = item.value - feeL1More
          txDataByAddress.set(address, item)
        }
      }
    } catch (error) {
      for (const address of txDataForEstimateByAddress.keys()) {
        const currentFee = feeDataByAddress.get(address)!
        feeDataByAddress.set(address, GasHelper.gasPricePlusPercent(currentFee, 10))
      }
    }

    return {txDataByAddress, feeDataByAddress}
  }
}

export {BASEFacade}