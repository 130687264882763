import {IWeb3Facade} from "../IWeb3Facade";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType,
  IAccount,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum,
  NetworkType,
  PrivateKeyType
} from "../../types";
import {DOGETokens} from "../../../../store/dogescan/DogeTokens";
import {setProviderWeb3, Web3DogeType} from "../../../../store/web3/web3Doge";
import {DogeUTXOResponse} from "../../../../store/dogescan/types";
import {CurrentUtxoFee} from "@tatumio/tatum";
import * as Sentry from "@sentry/react";
import {partitionArrayIntoChunks} from "../../../../helpers";

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  medium: "medium",
  fast: "fast"
} as const

interface IDataForGenerateDogeTransactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

export interface ITxDogeData extends IGeneralTxData {
  utxosData: DogeUTXOResponse,
  privateKey: PrivateKeyType,
  amount: bigint,
  receiverAddress: AddressType
}

export interface IDataForSendDogeTransactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  transactionDataByAddress: IMapValueByAddress<ITxDogeData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

const SATOSHI_PRE_DOGE = 100_000_000;
const FETCH_BALANCE_CHUNK_SIZE = 30;

class DOGEFacade implements IWeb3Facade {
  readonly defaultTransactionPriority: keyof ITransactionPriorityEnum;
  readonly linkForTxScan: string;
  readonly network: NetworkType;
  readonly tokensDict: ITokenDict;
  readonly transactionPriorityOptions: ITransactionPriorityEnum;
  readonly addressesChunkSize: number;
  readonly limitPrivateKeys: number;
  protected readonly _web3Provider: Web3DogeType;

  constructor() {
    this.defaultTransactionPriority = TransactionPriorityEnum.medium
    this.transactionPriorityOptions = {
      [TransactionPriorityEnum.slow]: "Low",
      [TransactionPriorityEnum.medium]: "Average",
      [TransactionPriorityEnum.fast]: "High",
    }
    this.linkForTxScan = process.env.REACT_APP_LINK_FOR_TX_DOGE_SCAN
    this.network = 'doge'
    this.tokensDict = DOGETokens

    this._web3Provider = setProviderWeb3(process.env.REACT_APP_API_KEY_FOR_DOGECHAIN_API, process.env.REACT_APP_ENVIRONMENT);

    this.limitPrivateKeys = 990;
    this.addressesChunkSize = 990;
  }


  getAddressesChunkSize(): number {
    return this.addressesChunkSize;
  }

  getLimitPrivateKeys(): number {
    return this.limitPrivateKeys;
  }

  getTimeout(): number {
    return 200;
  }

  async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<IGeneralTxData>, gasPriceInWei: bigint): Promise<EstimateResultType> {
    const txDataByAddress: IMapValueByAddress<ITxDogeData> = new Map()
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()
    return {txDataByAddress, feeDataByAddress};
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    const balanceByAddress: BalanceDataByAddress = new Map();

    const {getBatchBalanceData} = this._web3Provider

    for (const addressesChunk of partitionArrayIntoChunks(Array.from(addressList), FETCH_BALANCE_CHUNK_SIZE)) {
      await getBatchBalanceData(addressesChunk)
        .then((dogeBalancesData) => {
          for (let j = 0; j < addressesChunk.length; j++) {
            const incoming = this.toUnitFromBaseCurrency(dogeBalancesData[j].incoming);
            const outgoing = this.toUnitFromBaseCurrency(dogeBalancesData[j].outgoing);
            if (incoming > 0 && (incoming - outgoing) > 0) {
              balanceByAddress.set(addressesChunk[j], (incoming - outgoing))
            }
          }
        })
        .catch(error => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "DOGEFacade",
              method: "getBatchBalanceData"
            },
            contexts: {
              "fetchBalanceDataByAddress": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_keys: addressList.size,
              }
            }
          });
        })
    }

    return balanceByAddress
  }

  async generateTransactions(data: IDataForGenerateDogeTransactions): Promise<EstimateResultType> {
    const {transactionPriority, privateKeyByAddress, receiverAddress, baseCurrencyBalanceData} = data
    const {getCurrentFee, getUTXOsForAddresses} = this._web3Provider

    const txDataByAddress: IMapValueByAddress<ITxDogeData> = new Map()
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()

    const {done} = baseCurrencyBalanceData.entries().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    const feeData = await getCurrentFee();
    let senderAddresses: Array<AddressType> = [];

    let fee: number = Number(feeData[transactionPriority as keyof CurrentUtxoFee]);
    if (transactionPriority === TransactionPriorityEnum.slow) {
      /**
       * Returned value in "slow" field is too low for blockchain
       * So, use value "medium" divided by 2
       */
      fee = Number(feeData[TransactionPriorityEnum.medium as keyof CurrentUtxoFee]) / 2;
    }
    baseCurrencyBalanceData.forEach((balanceSender, addressSender) => {
      if (addressSender.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      if (balanceSender > this.toBaseCurrencyFromUnit(fee)) {
        senderAddresses.push(addressSender);
      }
      feeDataByAddress.set(addressSender, BigInt(Math.floor(fee)))
    });

    if (!senderAddresses.length) {
      return {txDataByAddress, feeDataByAddress}
    }

    const senderUTXOs = await getUTXOsForAddresses(senderAddresses);

    for (let addressUTXOInfo of senderUTXOs) {
      txDataByAddress.set(addressUTXOInfo.address, {
        utxosData: addressUTXOInfo,
        privateKey: privateKeyByAddress.get(addressUTXOInfo.address)!,
        amount: baseCurrencyBalanceData.get(addressUTXOInfo.address)!,
        receiverAddress,
      });
    }

    return {txDataByAddress, feeDataByAddress}
  }

  async sendTransactions(data: IDataForSendTransactions): Promise<IMapValueByAddress> {
    const {transactionPriority, transactionDataByAddress} = data as IDataForSendDogeTransactions

    const {sendRawDogeTransaction, getCurrentFee} = this._web3Provider

    const resultTxReceipt: IMapValueByAddress<string> = new Map()

    const feeData = await getCurrentFee();
    let fee: number = Number(feeData[transactionPriority as keyof CurrentUtxoFee]);
    if (transactionPriority === TransactionPriorityEnum.slow) {
      /**
       * Returned value in "slow" field is too low for blockchain
       * So, use value "medium" divided by 2
       */
      fee = Number(feeData[TransactionPriorityEnum.medium as keyof CurrentUtxoFee]) / 2;
    }

    for (const [senderAddress, txData] of transactionDataByAddress) {
      await sendRawDogeTransaction(txData, fee)
        .then(txHash => {
          resultTxReceipt.set(senderAddress, txHash.result!)
        });
    }

    return resultTxReceipt
  }

  toBaseCurrencyFromUnit(amount: string | number): number {
    return Number(amount) / SATOSHI_PRE_DOGE;
  }

  toUnitFromBaseCurrency(amount: string | number): bigint {
    return BigInt(Math.round(Number(amount) * SATOSHI_PRE_DOGE));
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    return {address: this._web3Provider.getAddressByPrivateKey(privateKey), privateKey};
  }

  validateAddress(address: AddressType): boolean {
    return this._web3Provider.isValidDogecoinAddress(address);
  }

  resetGasPriceAndNonce() {}
}

export {DOGEFacade}