import {
  IMapValueByAddress,
  PrivateKeyType, AddressType, SelectedCurrencyType
} from "../pages/ConsolidationTool/types";

export function getSortedBalances(
  privateKeysByAddress: IMapValueByAddress<PrivateKeyType>,
  balanceData: Map<SelectedCurrencyType, IMapValueByAddress<number>>
): IMapValueByAddress<bigint> {
  return new Map(
    [...privateKeysByAddress.entries()].sort((a, b) => {
      let firstAddress: AddressType = a[0]
      let secondAddress: AddressType = b[0]
      let firstValue: number = 0
      let secondValue: number = 0

      balanceData.forEach((balanceData) => {
        firstValue += balanceData.get(firstAddress) || 0
        secondValue += balanceData.get(secondAddress) || 0
      })

      return Number(secondValue - firstValue)
    })
  )
}