import {AddressHexStr, IContractAbiFragment, Uint256} from "../../models/chainScan.models";

/**
 * more info by next links
 * https://bscscan.com/address/0xD152f549545093347A162Dce210e7293f1452150#code
 * https://disperse.app/
 * https://disperse.app/disperse.pdf
 */
const BscDisperseContractAddress = "0xD152f549545093347A162Dce210e7293f1452150" as const

const BscTestnetDisperseContractAddress = "0x903cd41E63B7aEFfd11EE711258103d0B1f0c01A" as const

const MAX_TX_IN_BSC = 800
const MAX_TX_IN_TOKEN = 180

const BscDisperseABI: IContractAbiFragment[] = [{
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseTokenSimple",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseToken",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "recipients", "type": "address[]"}, {"name": "values", "type": "uint256[]"}],
  "name": "disperseEther",
  "outputs": [],
  "payable": true,
  "stateMutability": "payable",
  "type": "function"
}]

interface IBscDisperse {
  disperseEther(recipients: AddressHexStr[], values: Uint256[]): boolean,
}

export {BscDisperseABI, BscDisperseContractAddress, BscTestnetDisperseContractAddress, MAX_TX_IN_BSC, MAX_TX_IN_TOKEN}