import {useMemo} from 'react'
import ReactSelect, {Props} from 'react-select'
import CreatableSelect from 'react-select/creatable'

import DropdownIndicator from './DropDownIndicator'
import {customStyles} from './config'

interface SelectProps extends Props {
  creatable?: boolean
  isWarning?: boolean
  feedback?: string | null
  isInvalid?: boolean
  isValid?: boolean
  statusClass?: 'warning' | 'valid' | 'invalid'
  isSearchable?: boolean
}

const feedbackBlock = (
  feedback: SelectProps['feedback'],
  statusClass: SelectProps['statusClass']
) => {
  if (!feedback) return null

  return (
    <div className={statusClass + '-feedback feedback'}>{feedback}</div>
  )
}

export const MultiSelect = ({
                         creatable = false,
                         isWarning,
                         feedback,
                         isInvalid,
                         isValid,
                         isSearchable = true,
                         menuPlacement = 'bottom',
                         components,
                         className,
                         classNamePrefix,
                         ...props
                       }: SelectProps): JSX.Element => {
  const statusClass = useMemo(() => {
    if (isWarning) {
      return 'warning'
    }

    if (isInvalid) {
      return 'invalid'
    }

    if (isValid) {
      return 'valid'
    }

    return undefined
  }, [isWarning, isInvalid, isValid])

  const ComponentSelect = creatable ? CreatableSelect : ReactSelect

  return (
    <div className={`react-select-wrapper ${statusClass}`}>
      <ComponentSelect
        isMulti
        isSearchable={isSearchable}
        className={`react-select ${className ?? ''}`}
        classNamePrefix={`react-select ${classNamePrefix ?? ''}`}
        styles={customStyles}
        minMenuHeight={250}
        menuPlacement={menuPlacement}
        placeholder={
          props?.placeholder || 'Select'
        }
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ...components,
        }}
        {...props}
      />

      {feedbackBlock(feedback, statusClass)}
    </div>
  )
}
