import {
  AddressType,
  EstimateResultType,
  IMapValueByAddress,
  ITokenInfo,
  ITransactionPriorityEnum
} from "../../types";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {
  ERC20Facade,
  IDataForGenerateERC20Transactions,
  IDataForSendERC20Transactions,
  ITxErc20Data
} from "../ETH_Network/ERC20Facade";
import ERC20TokenABI from "../../../../store/etherscan/ERC20TokenABI";
import {BASEFacade, BaseInitData} from "./BASEFacade";
import {ApiScanResponse} from "../../../../models/chainScan.models";
import {GasHelper} from "../../../../helpers";
/**
 * Solution for L2 chain
 */
import {base, baseSepolia} from "viem/chains";
import {createPublicClient, http} from 'viem'
import {publicActionsL2} from 'viem/op-stack'
import {BASE_DEFAULT_IMG} from "../../../../store/basescan/BASETokens";

class BASE_ERC20Facade extends ERC20Facade implements IWeb3TokenFacade {
  constructor() {
    super({
      baseInitData: BaseInitData,
      tokenInitData: {
        defaultTokenImage: BASE_DEFAULT_IMG,
        abi: ERC20TokenABI,
        fetchTokenConf: {
          apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BASE_SCAN_API,
          url: process.env.REACT_APP_LINK_FOR_BASE_SCAN_API
        }
      },
      addressesChunkSize: 100
    });
  }

  getTimeout(): number {
    return 1000;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const parentFacade = new BASEFacade(BaseInitData)
    return parentFacade._fetchGasPriceInWei(transactionPriority)
  }

  async generateTransactions(data: IDataForGenerateERC20Transactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) {
      const parentFacade = new BASEFacade(BaseInitData)
      return await parentFacade.generateTransactions(data)
    }
    return await super.generateTransactions(data, tokenAddress)
  }

  async sendTransactions(data: IDataForSendERC20Transactions, tokenAddress?: AddressType): Promise<IMapValueByAddress> {
    if (!tokenAddress) {
      const parentFacade = new BASEFacade(BaseInitData)
      return await parentFacade.sendTransactions(data)
    }

    return super.sendTransactions(data)
  }

  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const params = {
      module: 'token',
      action: 'getToken',//TODO ONLY FOR blockscout.com/api/
      contractaddress: tokenAddress,
      apikey: this._fetchTokenConf.apikey
    }
    const data: ApiScanResponse<{
      cataloged: boolean;
      contractAddress: string;
      decimals: string;
      name: string;
      symbol: string;
      totalSupply: string;
      type: string;
    }> = await fetch(
      this._fetchTokenConf.url + '/?' + new URLSearchParams(params),
      {cache: "force-cache"}
    ).then(response => response.json())

    if (parseInt(data?.status || '0') === 0 && !data.result) {
      throw new Error(data.message)
    }

    if (parseInt(data?.status || '0') === 1 && Object.values(data.result).length) {
      const dataToken = data.result

      return {
        symbol: dataToken.symbol,
        title: dataToken.name,
        address: dataToken.contractAddress,
        decimal: Number(dataToken.decimals),
        img: this.tokensDict[dataToken?.symbol]?.img || this.defaultTokenImage
      };
    }
    throw new Error("Something was wrong...");
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)

    console.log('base20')
    /**
     * Solution for L2 chain
     */
    const publicClient: any = createPublicClient({
      chain: this._environment === 'dev' ? baseSepolia : base,
      transport: http(),
    } as any).extend(publicActionsL2())

    const txDataByAddressIterator = txDataByAddress.values()
    const {done, value} = txDataByAddressIterator.next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    let item: ITxErc20Data = value
    /**
     * Solution if fee greater than balance
     * estimateL1Fee throws an exception if TX Value is lower than 0
     */
    while (item.value < 0) {
      const {done, value} = txDataByAddressIterator.next()
      if (done) {
        return {txDataByAddress, feeDataByAddress}
      }

      item = value
    }

    /**
     * Solution to estimate if balance too small to send transaction
     */
    try {
      const feeL1 = await publicClient.estimateL1Fee({
        account: item.from,
        to: item.to,
        data: item.data,
      })
      const feeL1More = GasHelper.gasPay(feeL1)

      for (const address of txDataForEstimateByAddress.keys()) {
        const currentFee = feeDataByAddress.get(address)!
        feeDataByAddress.set(address, GasHelper.gasPay(currentFee) + feeL1More)
      }
    } catch (error) {
      for (const address of txDataForEstimateByAddress.keys()) {
        const currentFee = feeDataByAddress.get(address)!
        feeDataByAddress.set(address, GasHelper.gasPricePlusPercent(currentFee, 10))
      }
    }

    return {txDataByAddress, feeDataByAddress}
  }
}

export {BASE_ERC20Facade};