import {ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {GasHelper} from "../../../helpers";
import {
  BaseDisperseABI,
  BaseDisperseContractAddress,
  BaseTestnetDisperseContractAddress
} from "../../../store/basescan/BASEDisperseABI";
import {AddressHexStr, IContractAbiFragment, ITxDataSimple} from "../../../models/chainScan.models";
import {createPublicClient, http} from "viem";
import {base, baseSepolia} from "viem/chains";
import {publicActionsL2} from "viem/op-stack";
import {HexStr, toHex} from "../../../store/web3/web3";
import {
  IDataGenerateDisperseTransactions,
  IEstimateFeeForDisperseResultType
} from "../types";

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IBaseGasPrice {
  slow: bigint,
  average: bigint,
  fast: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IBaseGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class BASE_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected _disperseContractABI: IContractAbiFragment[]
  protected _disperseContractAddress: AddressHexStr

  protected static _feeL1ForSingleTransaction: bigint = BigInt(0)
  protected static _feeL1ForMultipleTransaction: bigint = BigInt(0)

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_BASE_WEB3_HTTP_PROVIDER,
      network: "base",
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BASE_SCAN,
      defaultTransactionPriority: "fast",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API_KEY,
        url: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API,
        devUrl: process.env.REACT_APP_LINK_FOR_BASE_SCAN_API
      },
      addressesChunkSize: 80
    })

    this._disperseContractAddress = process.env.REACT_APP_ENVIRONMENT === 'dev' ?
      BaseTestnetDisperseContractAddress : BaseDisperseContractAddress
    this._disperseContractABI = BaseDisperseABI
  }

  async estimateTransactions(data: IDataGenerateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const estimatedFee = await super.estimateTransactions(data)

    const {amountInUnitByReceiver, senderAddress} = data
    const {value: toAddress} = amountInUnitByReceiver.keys().next()

    if (BASE_DisperseFacade._feeL1ForSingleTransaction === BigInt(0)) {
      const estimateFeeL1ForSingleTransactionData: ITxDataSimple = {
        value: toHex(amountInUnitByReceiver.get(toAddress)!),
        from: senderAddress,
        to: toAddress
      }
      BASE_DisperseFacade._feeL1ForSingleTransaction = await this._fetchFeeForL1(estimateFeeL1ForSingleTransactionData)
    }
    estimatedFee.notOptimizedFeeInUnit += BASE_DisperseFacade._feeL1ForSingleTransaction * BigInt(amountInUnitByReceiver.size)

    const {getDisperseContract} = this._web3Provider
    const {disperseEther} = getDisperseContract(this._disperseContractABI, this._disperseContractAddress)

    let totalSendInUnit: bigint = BigInt(0)
    amountInUnitByReceiver.forEach(amount => totalSendInUnit += amount)

    if (BASE_DisperseFacade._feeL1ForMultipleTransaction === BigInt(0)) {
      const estimateFeeL1ForMultiTransactionData: ITxDataSimple = {
        value: toHex(totalSendInUnit),
        from: senderAddress,
        to: this._disperseContractAddress,
        data: disperseEther(
          [...amountInUnitByReceiver.keys()] as AddressHexStr[],
          [...amountInUnitByReceiver.values()] as bigint[]
        ).encodeABI()
      }
      BASE_DisperseFacade._feeL1ForMultipleTransaction = await this._fetchFeeForL1(estimateFeeL1ForMultiTransactionData)
    }
    estimatedFee.optimizedFeeInUnit += BASE_DisperseFacade._feeL1ForMultipleTransaction

    return estimatedFee
  }

  resetInfoForSendTransaction() {
    super.resetInfoForSendTransaction();
    BASE_DisperseFacade._feeL1ForSingleTransaction = BigInt(0)
    BASE_DisperseFacade._feeL1ForMultipleTransaction = BigInt(0)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IBaseGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "id": 0,
          "method": "eth_gasPrice",
          "jsonrpc": "2.0",
          "params": []
        })
      })
      const result = await response.json() as {
        "jsonrpc": string,
        "result": HexStr,
        "id": number
      }
      const slowInWei: bigint = BigInt(result.result)
      if (transactionPriority === 'slow') {
        return GasHelper.gasPricePlusPercent(slowInWei, 100)
      } else {
        return GasHelper.gasPricePlusPercent(slowInWei, 150)
      }
    }

    return gasPriceResult[transactionPriority as keyof IBaseGasPrice] || BigInt(0)
  }

  async _fetchFeeForL1(txData: ITxDataSimple) {
    const publicClient: any = createPublicClient({
      chain: this._environment === 'dev' ? baseSepolia : base,
      transport: http(),
    } as any).extend(publicActionsL2())

    const feeL1 = await publicClient.estimateL1Fee(txData)
    console.log("=> feeL1", feeL1);
    return GasHelper.gasPay(feeL1)
  }
}

export {BASE_DisperseFacade}