import {AddressHexStr, IContractAbiFragment, Uint256} from "../../models/chainScan.models";

/**
 * more info by next links
 * https://basescan.org/address/0xD152f549545093347A162Dce210e7293f1452150#code
 * https://disperse.app/
 * https://disperse.app/disperse.pdf
 */
const BaseDisperseContractAddress = "0xD152f549545093347A162Dce210e7293f1452150" as const

const BaseTestnetDisperseContractAddress = "0xee99bC60FbE08303A8dF370Dbf2364663e2a7008" as const
const MAX_TX_IN_ETH = 800
const MAX_TX_IN_TOKEN = 180
const BaseDisperseABI: IContractAbiFragment[] = [{
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseTokenSimple",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseToken",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "recipients", "type": "address[]"}, {"name": "values", "type": "uint256[]"}],
  "name": "disperseEther",
  "outputs": [],
  "payable": true,
  "stateMutability": "payable",
  "type": "function"
}]

interface IBscDisperse {
  disperseEther(recipients: AddressHexStr[], values: Uint256[]): boolean,
}

export {
  BaseDisperseABI,
  BaseDisperseContractAddress,
  BaseTestnetDisperseContractAddress,
  MAX_TX_IN_ETH,
  MAX_TX_IN_TOKEN
}