import { StylesConfig } from 'react-select'

export const customStyles: StylesConfig = {
	control: (styles, { isDisabled }) => ({
		...styles,
		backgroundColor: isDisabled ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
		borderColor: 'rgba(255, 255, 255, 0.6)',
		borderRadius: '5px',
		boxShadow: 'none',
		minHeight: '48px',
		cursor: isDisabled ? 'not-allowed' : 'pointer',

		':hover': {
			...styles[':hover'],
			borderColor: 'rgba(255, 255, 255, 0.8)',
		},
	}),
	input: (styles, { isDisabled }) => ({
		...styles,
		color: 'white',
		fontWeight: 500,
		fontSize: '16px',
		cursor: isDisabled ? 'not-allowed' : 'pointer',
	}),
	placeholder: (styles) => ({
		...styles,
		color: '#A8B0C1',
		fontSize: '16px',
	}),
	menu: (styles) =>
		Object.assign(styles, {
			zIndex: 1000,
			background: 'rgba(23, 23, 26, 0.8)',
			backdropFilter: 'blur(15px)',
			borderRadius: 15,
			padding: '20px 0',
		}),
	menuList: (styles) =>
		Object.assign(styles, {
			padding: '0 20px',
		}),
	option: (provided, state) => ({
		...provided,
		position: 'relative',
		backgroundColor: 'none',
		borderRadius: '15px',
		border: 'none',
		color: '#FFF',
		padding: '20px 44px 20px 20px',
		fontWeight: 500,
		cursor: !state.isSelected && state.isFocused ? 'pointer' : 'not-allowed',
		transition: 'background-color .3s ease-in-out',

		':after': {
			content: "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_10406_5194)'%3E%3Cpath d='M9 6L15 12L9 18' stroke='%23A8B0C1' stroke-width='0.916667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_10406_5194'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A\")",
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 20,
			width: 24,
			height: 24,
			fontSize: '24px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},

		':hover': {
			backgroundColor: state.isSelected ? 'none' : 'rgba(255, 255, 255, 0.05)',
		},
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		transition: 'color, transform 150ms',
		padding: '2px 20px',

		'.fa-caret-down': {
			transition: 'all .3s ease-in-out',
			transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'none',
		},
	}),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: "transparent"
	}),
	multiValueLabel: (provided) => {
		const transition = 'opacity 300ms'

		return {
			...provided,
			transition,
			color: 'white',
			fontWeight: 500,
			fontSize: '16px',
		}
	},
	valueContainer: (styles) => {
		return {
			...styles,
			padding: '2px 0 2px 5px',
		}
	},
}
