import {
  Client,
  decodeSeed,
  dropsToXrp,
  FeeResponse,
  isValidClassicAddress,
  ServerStateRequest,
  ServerStateResponse,
  Wallet,
  xrpToDrops,
} from "xrpl";
import type {LedgerIndex} from "xrpl/src/models/common";
import {AccountInfoRequest, SubmitResponse} from "xrpl/src/models/methods";
import {ITxXrpData} from "../../pages/ConsolidationTool/facades/XRP_Network/XRPFacade";
import {PrivateKeyType} from "../../pages/ConsolidationTool/types";
import {FeeRequest} from "xrpl/src/models/methods/fee";
import {publicKeyCreate} from "secp256k1";

export function setProviderWeb3(linkHttpProvider: string,) {
  const xrplClient = new Client(linkHttpProvider)
  let _isConnect = false


  function connectDecorator<Args extends any[], Return>(fn: (...args: Args) => Promise<Return>) {
    return async function replacementMethod(...args: Args): Promise<Return> {
      if (!_isConnect) {
        await xrplClient.connect()
        _isConnect = true
      }

      // await xrplClient.disconnect()
      return await fn.call(xrplClient, ...args)
    }
  }

  /**
   * @inheritDoc https://xrpl.org/docs/concepts/transactions/transaction-cost#server_state
   *
   * Current Transaction Cost in Drops = (base_fee × load_factor) ÷ load_base
   *
   * @see connectDecorator
   * [this] already set in connectDecorator as xrplClient
   */
  async function getFee(): Promise<{ min: bigint, reserve: bigint }> {

    const serverInfo: ServerStateResponse = await this.request({
      command: 'server_state',
      ledger_index: "current"
    } as ServerStateRequest)

    console.log('serverInfo=', serverInfo)

    const feeInfo: FeeResponse = await this.request({
      command: 'fee'
    } as FeeRequest)

    console.log('feeInfo=', feeInfo)

    const fee = (feeInfo.result.drops.base_fee * serverInfo.result.state.load_factor) / serverInfo.result.state.load_base

    return {
      /**
       * @inheritDoc https://xrpl.org/docs/concepts/transactions/transaction-cost#current-transaction-cost
       */
      min: BigInt(fee * this.feeCushion),
      /**
       * @inheritDoc https://xrpl.org/docs/concepts/accounts/reserves/#looking-up-reserves
       * https://xrpl.org/docs/concepts/accounts/reserves/#reserves
       */
      reserve: BigInt(serverInfo.result.state.validated_ledger?.reserve_base || 200000)
    }
  }

  async function getXrpBalanceInDrops(
    address: string,
    options: {
      ledger_hash?: string
      ledger_index?: LedgerIndex
    } = {}): Promise<bigint> {
    const xrpRequest: AccountInfoRequest = {
      command: 'account_info',
      account: address,
      ledger_index: options.ledger_index ?? 'validated',
      ledger_hash: options.ledger_hash,
    }

    /**
     * @see connectDecorator
     * [this] already set in connectDecorator as xrplClient
     */
    const response = await this.request(xrpRequest)

    return BigInt(response.result.account_data.Balance || 0)
  }

  function __isSeed(seed: string) {
    //The seed (Secret) is only 31 characters long.
    const SEED_LENGTH = 31 as const

    return seed.length === SEED_LENGTH
  }

  function getWallet(privateKey: PrivateKeyType) {
    if (__isSeed(privateKey) && !!decodeSeed(privateKey)) {
      return Wallet.fromSeed(privateKey)
    } else {
      /**
       * @var {string{64}} privateKey   ONLY secp256k1{64 length} for ed25519 need add logic
       * @inheritDoc https://runkit.com/wietsewind/hex-private-key-to-address-with-ripple-lib
       */
      const pubKey = Buffer.from(publicKeyCreate(Buffer.from(privateKey, 'hex'))).toString('hex');
      return new Wallet(pubKey, `${privateKey}`)
    }
  }

  /**
   * @see connectDecorator
   * [this] already set in connectDecorator as xrplClient
   */
  async function sendXrp(
    txData: ITxXrpData,
    secretForSign: PrivateKeyType): Promise<SubmitResponse> {

    const walletSender = getWallet(secretForSign)

    // Prepare transaction -------------------------------------------------------
    const prepared = await this.autofill(txData)
    const max_ledger = prepared.LastLedgerSequence

    console.log("Prepared transaction instructions:", prepared)
    console.log("Transaction cost:", dropsToXrp(prepared.Fee), "XRP")
    console.log("Transaction expires after ledger:", max_ledger)

    // Sign prepared instructions ------------------------------------------------
    const signed = walletSender.sign(prepared)
    console.log("Identifying hash:", signed.hash)

    // Submit signed blob --------------------------------------------------------
    return await this.submit(signed.tx_blob) as SubmitResponse
  }

  return {
    isValidClassicAddress,
    sendXrp: connectDecorator(sendXrp),
    getFee: connectDecorator(getFee),
    getXrpBalanceInDrops: connectDecorator(getXrpBalanceInDrops),
    xrpToDrops,
    dropsToXrp,
    getWallet,
  }
}

export type Web3XrpType = ReturnType<typeof setProviderWeb3>

