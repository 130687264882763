import {IWeb3Facade} from "../IWeb3Facade";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType,
  IAccount,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkCurrencyEnum,
  NetworkType,
  PrivateKeyType
} from "../../types";
import {XRPTokens} from "../../../../store/xrpscan/XRPTokens";
import {setProviderWeb3, Web3XrpType} from "../../../../store/web3/web3Xrp";
import * as Sentry from "@sentry/react";

interface IDataForGenerateXRPTransactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

export interface IDataForSendXRPTransactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxXrpData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

export interface ITxXrpData extends IGeneralTxData {
  TransactionType: 'Payment',
  Account: AddressType,
  Amount: string,
  Fee?: string,
  Destination: AddressType,
}

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  min: "min",
} as const

class XRPFacade implements IWeb3Facade {
  protected readonly _linkForTxScan: string;
  protected readonly _defaultTransactionPriority: keyof typeof TransactionPriorityEnum;
  protected readonly _transactionPriorityOptions: ITransactionPriorityEnum;
  protected readonly _tokensDict: ITokenDict;
  protected readonly _network: NetworkType;
  protected readonly _web3Provider: Web3XrpType;
  protected readonly limitPrivateKeys;
  protected readonly addressesChunkSize;

  constructor() {
    this._defaultTransactionPriority = TransactionPriorityEnum.min
    this._transactionPriorityOptions = {
      [TransactionPriorityEnum.min]: "Min",
    }
    this._tokensDict = XRPTokens
    this._network = 'xrp'
    this._linkForTxScan = process.env.REACT_APP_LINK_FOR_TX_XRP_SCAN

    /**
     * wss://go.getblock.io/43848ad81bf94fe99f84c53393745186
     * wss://crimson-silent-crater.xrp-mainnet.quiknode.pro/39fafe5811cfd341afa6a7e0abdf1f6ece56cb89/
     *  wss://s1.ripple.com/
     *  wss://xrplcluster.com/
     *  wss://s2.ripple.com/
     */
    this._web3Provider = setProviderWeb3(process.env.REACT_APP_XRP_WEB3_WSS_PROVIDER)

    this.limitPrivateKeys = 990
    this.addressesChunkSize = 990
  }

  get tokensDict() {
    return this._tokensDict
  }

  get network() {
    return this._network
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get defaultTransactionPriority() {
    return this._defaultTransactionPriority
  }

  get transactionPriorityOptions() {
    return this._transactionPriorityOptions
  }

  getLimitPrivateKeys() {
    return this.limitPrivateKeys
  }

  getAddressesChunkSize() {
    return this.addressesChunkSize
  }

  getTimeout(): number {
    return 200;
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    const balanceByAddress: BalanceDataByAddress = new Map();

    const {getXrpBalanceInDrops} = this._web3Provider

    // Map each address to a Promise that fetches its balance
    const promises: Promise<boolean>[] = [];

    addressList.forEach(address =>
      promises.push(getXrpBalanceInDrops(address, {})
        .then((xrpBalance) => {
          if (xrpBalance && xrpBalance > 0) {
            balanceByAddress.set(address, xrpBalance)
          }
          return true
        })
        //TODO set error by callBack fn => serErr(address,error)
        .catch(error => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "XRPFacade",
              method: "getXrpBalanceInDrops"
            },
            contexts: {
              "fetchBalanceDataByAddress": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_keys: addressList.size,
              }
            }
          });
          return false
        })
      ))


    // Wait for all promises to resolve
    await Promise.all(promises);

    return balanceByAddress
  }

  async generateTransactions(
    data: IDataForGenerateXRPTransactions
  ): Promise<EstimateResultType> {
    const {baseCurrencyBalanceData, transactionPriority, receiverAddress} = data
    const {getFee} = this._web3Provider

    const txDataByAddress: IMapValueByAddress<ITxXrpData> = new Map()
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()

    const {done} = baseCurrencyBalanceData.entries().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    const feeData = await getFee()

    const fee: bigint = feeData[transactionPriority]
    const totalFee = (fee + feeData.reserve)
    baseCurrencyBalanceData.forEach((balanceSender, addressSender) => {
      if (addressSender.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      if (balanceSender > totalFee) {
        const tx: ITxXrpData = {
          TransactionType: 'Payment',
          Account: addressSender,
          Amount: (balanceSender - totalFee).toString(),
          Fee: fee.toString(),
          Destination: receiverAddress,
        }
        txDataByAddress.set(addressSender, tx)
      }
      feeDataByAddress.set(addressSender, totalFee)
    })

    return {txDataByAddress, feeDataByAddress}
  }

  async sendTransactions(data: IDataForSendTransactions): Promise<IMapValueByAddress> {
    const {privateKeyByAddress, transactionDataByAddress} = data as IDataForSendXRPTransactions

    const {sendXrp} = this._web3Provider

    const resultTxReceipt: IMapValueByAddress<string> = new Map()
    const promisses: Promise<boolean>[] = [];

    transactionDataByAddress.forEach((txData, senderAddress) => {
      promisses.push(new Promise(resolve => {
        sendXrp(txData, privateKeyByAddress.get(senderAddress) as PrivateKeyType)
          .then(response => {
            if (response.result && response.result.tx_json.hash) {
              resultTxReceipt.set(senderAddress, response.result.tx_json.hash)
            }
            resolve(true)
          });
      }))
    })

    await Promise.allSettled(promisses)

    return resultTxReceipt
  }


  validateAddress(address: AddressType): boolean {
    try {
      return this._web3Provider.isValidClassicAddress(address)
    } catch (e) {
      return false
    }
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    const {getWallet} = this._web3Provider
    const wallet = getWallet(privateKey)

    return {
      address: wallet.address,
      /**
       * //seed or private key (ONLY secp256k1, for ed25519 need add logic) from input
       * @inheritDoc https://runkit.com/wietsewind/hex-private-key-to-address-with-ripple-lib
       */
      privateKey: privateKey
    }
  }

  toUnitFromBaseCurrency(amount: bigint | string | number): bigint {
    return BigInt(this._web3Provider.xrpToDrops(amount.toString()))
  }

  toBaseCurrencyFromUnit(amount: bigint | string | number): number {
    return this._web3Provider.dropsToXrp(amount.toString())
  }

  resetGasPriceAndNonce() {
  }

  /**
   * TODO need refactor generateTransactions to separate for two methods
   * @deprecated until refactor
   *
   * @param txDataForEstimateByAddress
   * @param gasPriceInWei
   * @protected
   */
  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<IGeneralTxData>, gasPriceInWei: bigint): Promise<EstimateResultType> {
    const txDataByAddress: IMapValueByAddress<ITxXrpData> = new Map()
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()
    return {txDataByAddress, feeDataByAddress};
  }
}

export {XRPFacade}